import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { ImagePlugin, LayoutPlugin, CollapsePlugin } from "bootstrap-vue";
import Vue2TouchEvents from "vue2-touch-events";
import Vuelidate from "vuelidate";
import { VueReCaptcha } from "vue-recaptcha-v3";
import ObjectToFD from "vue-object-to-formdata";

import { polyfill } from "seamless-scroll-polyfill";
import scrollSnapPolyfill from "css-scroll-snap-polyfill";
import VueObserveVisibility from "vue-observe-visibility";
import "animate.css";

polyfill();
scrollSnapPolyfill();

Vue.config.productionTip = false;

Vue.use(ImagePlugin);
Vue.use(LayoutPlugin);
Vue.use(CollapsePlugin);

Vue.use(Vue2TouchEvents, {
  disableClick: true,
});

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY });

Vue.use(ObjectToFD);

Vue.use(Vuelidate);

Vue.use(VueObserveVisibility);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
