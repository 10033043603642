const Routes = {
  HOME: "home",
  CAREERS: "careers",
  PRIVACY_POLICY: "privacy-policy",
  OFFER: "offer",
};

const RouteSections = {
  HERO: "#hero",
  WHAT_WE_DO: "#what-we-do",
  CLIENTS: "#clients",
  TEAM: "#team",
  OFFICES: "#offices",
  SERVICES: "#services",
  WORK: "#work",
  LETS_TALK: "#lets-talk",
  JOIN_US: "#join-us",
};

const RouteSectionNames = {
  JOIN_US: "join-us",
};

const LinkedInProfiles = {
  WN: "https://uk.linkedin.com/in/wojciech-nowak-73a7267",
};

export { Routes, RouteSections, RouteSectionNames, LinkedInProfiles };
