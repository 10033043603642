<template>
  <div class="overlay-filler">
    <b-overlay no-wrap fixed show z-index="1050" :opacity="0">
      <template #overlay>
        <div class="lds-ellipsis">
          <div v-for="ellipse in 4" :key="`ellipse-${ellipse}`" />
        </div>
      </template>
    </b-overlay>
  </div>
</template>
<script>
import { BOverlay } from "bootstrap-vue";
export default {
  name: "TLoader",
  components: {
    BOverlay,
  },
  mounted() {
    document.body.classList.add("loader-active");
  },
  destroyed() {
    document.body.classList.remove("loader-active");
  },
};
</script>
