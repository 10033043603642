import throttle from "lodash.throttle";

export default {
  methods: {
    storeScroll() {
      document.documentElement.dataset.scroll = window.scrollY;
    },
  },
  created() {
    this.storeScroll();
    document.addEventListener("scroll", throttle(this.storeScroll, 500));
  },
  beforeDestroy() {
    document.removeEventListener("scroll", throttle(this.storeScroll, 500));
  },
};
