<template>
  <div id="app">
    <o-nav-bar
      :alwaysNotTransparent="['privacy-policy', 'offer'].includes($route.name)"
      @scroll-into="scrollIntoView"
    />
    <router-view ref="routerView" @scroll-into="scrollIntoView" />
    <o-footer
      id="footer"
      @scroll-into="scrollIntoView"
      :showReturnTop="$route.name !== 'privacy-policy'"
    />
  </div>
</template>

<script>
import dataScrollMixin from "@/mixins/data-scroll";

export default {
  name: "App",
  mixins: [dataScrollMixin],
  components: {
    ONavBar: () =>
      import(/* webpackChunkName: "navigation" */ "@/components/_organisms/navbar/o-navbar"),
    OFooter: () =>
      import(/* webpackChunkName: "footer" */ "@/components/_organisms/footer/o-footer"),
  },
  methods: {
    scrollIntoView(ref) {
      this.$refs.routerView?.$refs?.[ref]?.$el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/styles.scss";
</style>
