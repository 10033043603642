import Vue from "vue";
import VueRouter from "vue-router";
import TLoader from "@/components/_templates/loader/t-loader";
import { Routes } from "@/helpers/routes.js";

Vue.use(VueRouter);

const lazyLoadView = ({ component, loading = TLoader, delay = 0 }) => {
  const AsyncHandler = () => ({
    component,
    loading,
    delay,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children);
    },
  });
};

const routes = [
  {
    path: "/",
    name: Routes.HOME,
    component: () =>
      lazyLoadView({
        component: import(/* webpackChunkName: "v-home" */ "@/components/_views/home/v-home"),
      }),
  },
  {
    path: `/${Routes.PRIVACY_POLICY}`,
    name: Routes.PRIVACY_POLICY,
    component: () =>
      lazyLoadView({
        component: import(/* webpackChunkName: "v-pp" */ "@/components/_views/privacy-policy/v-privacy-policy"),
      }),
  },
  {
    path: `/${Routes.CAREERS}`,
    name: Routes.CAREERS,
    component: () =>
      lazyLoadView({
        component: import(/* webpackChunkName: "v-careers" */ "@/components/_views/careers/v-careers"),
      }),
  },
  {
    path: `/${Routes.OFFER}/:id`,
    name: Routes.OFFER,
    props: (route) => ({ positionId: route.params.id }),
    component: () =>
      lazyLoadView({
        component: import(/* webpackChunkName: "v-offer" */ "@/components/_views/offer/v-offer"),
      }),
  },
  { path: "*", redirect: { name: Routes.HOME } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (to === from) {
      // TODO: TEC-227: to implement, we need to wait for all images to load on website,
      // as they impact the placement of a section. For now just remove hash to not mislead user
      history.replaceState("", document.title, window.location.pathname + window.location.search);
      return;
    }
    if (to.hash) {
      const elem = document.querySelector(to.hash);
      if (elem) {
        const scrollTop = getComputedStyle(elem).scrollMarginTop || getComputedStyle(elem).scrollSnapMarginTop;
        return {
          selector: to.hash,
          offset: { y: parseFloat(scrollTop) },
        };
      }
    }
    return { x: 0, y: 0 };
  },
});

export default router;
